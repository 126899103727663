import PropTypes from 'prop-types'
import React from 'react'
import { useState, useEffect, useRef } from 'react'

//import { Parallax } from 'react-skrollr'
import { makeStyles } from 'tss-react/mui'

import { isSafariLike } from '../../utils/runtimeEnvDetectionUtils'
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
const useStyles = makeStyles()((theme, props) => ({
  content: {
    [theme.breakpoints.down('md')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 120px',
    },
  },
  contentBg: {
    background: `rgba(17, 17, 17, ${props.darken})`,
    width: '100%',
    height: '100%',
    minHeight: 'inherit',
  },
  background: {
    backgroundImage: props.backgroundImage,
    //backgroundImage: `url(https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg)`,
    backgroundPosition: '50% 0',
    backgroundSize: 'cover',
    backgroundAttachment: props.isSafariLike ? 'scroll' : 'fixed',
    [theme.breakpoints[props.transform.breakpointDirection](
      props.transform.breakpoint
    )]: {
      transform: props.transform.scale && `scale(${props.transform.scale})`,
    },
    ...props.style,
  },
}))

const ParallaxSection = props => {
  const darken = props.darken || 0.6
  const [scrollOffset, setScrollOffset] = useState(0)

  const scrollRef = useRef()
  const { classes } = useStyles({
    darken,
    style: props.backgroundStyle,
    backgroundImage: props.backgroundImage,
    transform: props.transform,
    isSafariLike,
  })

  useEffect(() => {
    const onScroll = () => {
      if (scrollRef.current) {
        const offset = scrollRef.current.getBoundingClientRect().top
        setScrollOffset(offset)
      }
    }
    window.addEventListener('scroll', onScroll)
    onScroll()

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div className={classes.background} ref={scrollRef}>
      <div className={classes.contentBg}>
        <div className={classes.content}>
          {props.children ? props.children(scrollOffset) : null}
        </div>
      </div>
    </div>
  )
}

ParallaxSection.propTypes = {
  children: PropTypes.func,
  darken: PropTypes.number,
  style: PropTypes.object,
  backgroundImage: PropTypes.string.isRequired,
  transform: PropTypes.shape({
    scale: PropTypes.number,
    breakpointDirection: PropTypes.oneOf(['up', 'down']),
    breakpoint: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  }),
}

ParallaxSection.defaultProps = {
  darken: 0,
  backgroundStyle: { minHeight: '100%' },
  transform: {
    scale: undefined,
    breakpointDirection: 'down',
    breakpoint: 'xl',
  },
}

export default ParallaxSection
