import React from 'react'
import ParallaxSection from '../components/titlePage/parallaxSection'
import Layout from '../templates/layout'
import SEO from '../components/seo'
import HeaderBranding from '../components/titlePage/headerBranding'
import NewsSection from '../components/titlePage/newsSection'
import AboutSection from '../components/titlePage/aboutSection'
import ContactSection from '../components/titlePage/contactSection'
import { viewContainerHeightMixin } from '../templates/themeProvider'
import PortfolioSection from '../components/titlePage/portfolioSection'
import BGDoor from '../images/parallax/bg-door.jpg'
import BGPiano from '../images/parallax/bg-piano.png'
import BGTurntable from '../images/parallax/bg-turntable.jpg'
import BGGuitar from '../images/parallax/bg-guitar.jpg'
import BGKeyboard from '../images/parallax/bg-keyboard.jpg'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <ParallaxSection
        backgroundStyle={viewContainerHeightMixin()}
        backgroundImage={`url(${BGDoor})`}
      >
        {scrollOffset => {
          const offset = (scrollOffset - 56) * -1
          const opacity = offset <= 1 ? 1 : 1 / (offset * offset * 0.001)
          return <HeaderBranding style={{ opacity }} />
        }}
      </ParallaxSection>
      <ParallaxSection
        backgroundStyle={viewContainerHeightMixin(
          'minHeight',
          cssCalc => `calc(${cssCalc} + 200px)`
        )}
        backgroundImage={`url(${BGPiano})`}
      >
        {() => <NewsSection />}
      </ParallaxSection>
      <ParallaxSection
        backgroundStyle={viewContainerHeightMixin()}
        backgroundImage={`url(${BGTurntable})`}
      >
        {() => <AboutSection />}
      </ParallaxSection>
      <ParallaxSection
        backgroundStyle={viewContainerHeightMixin()}
        backgroundImage={`url(${BGGuitar})`}
      >
        {() => <ContactSection />}
      </ParallaxSection>
      <ParallaxSection
        backgroundStyle={viewContainerHeightMixin()}
        backgroundImage={`url(${BGKeyboard})`}
      >
        {() => <PortfolioSection />}
      </ParallaxSection>
    </Layout>
  )
}

export default IndexPage
