import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    paddingLeft: '5%',
    paddingTop: '5%',
    [theme.breakpoints.up('md')]: {
      paddingTop: '250px',
      paddingLeft: '120px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '60px',
      paddingTop: '20%',
    },
  },
}));

const HeaderBranding = props => {
  const { classes } = useStyles()
  return (
    <div {...props} className={classes.root}>
      <Typography variant="h1">Andrea Bögel</Typography>
      <Typography variant="h2">Jazz – Klassik – Chanson</Typography>
    </div>
  )
}

export default HeaderBranding
