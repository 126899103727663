import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui';
import { LinkBase } from "../link"

const useStyles = makeStyles()(theme => ({
  root: {
    paddingTop: '85px',
    paddingBottom: '50px',
  },
  content: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  heading: {
    paddingBottom: '20px',
    marginBottom: '40px',
    borderBottom: '2px solid #fff',
  },
  paragraph: {
    marginBottom: '50px',
  },
  quote: {
    fontStyle: 'italic',
  },
}));

const AboutSection = props => {
  const { classes } = useStyles()
  return (
    <div {...props} className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1" className={classes.heading}>
          Andrea Bögel
        </Typography>
        <Typography className={classes.paragraph}>
          Andrea Bögel arbeitet als Solistin in den Bereichen Swing, Klassik und
          Chanson. Seit ihren Anfängen schreibt sie auch eigene Texte und
          komponiert. Als Leiterin und Coach im{' '}
          <LinkBase href="http://songstudiofreiburg.de/">Songstudio Freiburg</LinkBase>{' '}
          arbeitet sie mit vielen etablierten und angehenden Künstlern.
        </Typography>
        <Typography className={classes.paragraph} align="right">
          <span className={classes.quote}>
            „Andrea Bögel gehört zu den Stipendiatinnen, die eine internationale
            Karriere gemacht haben und sich nun ihrerseits für den jüngeren
            Nachwuchs engagieren. In diesem Konzert tritt sie mit dem „Fyran
            Jazz-Quartett“ auf, das sich an der Schwelle zu einer
            internationalen Karriere befindet. Alle zeigen, dass die
            Förderungspraxis unserer Stiftung offentsichtlich richtig und
            erfolgreich ist.“
          </span>
          <br />
          Prof. Dr. Hermann Rauhe zum Konzert der Vera-Ritter-Stiftung in den
          Mozartsälen Hamburg, 11.09.2007
        </Typography>
        <Typography className={classes.paragraph} align="right">
          <span className={classes.quote}>
            „Dem entsprechend gibt es auf ihrer CD Songs von George Gershwin,
            Michel Legrand, Kurt Weill und anderen. Bögels Stimme findet sich in
            dem disparaten Repertoire glänzend zurecht. Den Musical Melodien
            drückt sie mit eigenwilligem Vibrato ihren Stempel auf, stilsicher
            gibt sie sich im Gershwin-Medley, von einem swingenden Trio
            begleitet. Dann zarte Duos, bei denen lyrische Momente anklingen.
            Sie weiß sich in Szene zu setzen in diesem munteren Mix aus Texten
            auf Englisch, Deutsch und einmal Französisch. Den Reigen der 13
            Lieder beschließt ein überraschendes „Summertime“, das opernhaft im
            höchsten Sopran dargeboten wird. Erstaunlich, wie wandelbar diese
            Stimme ist, allzeit von höchster Spannkraft.“
          </span>
          <br />
          Reiner Kobe (Badische Zeitung), 2006
        </Typography>
      </div>
    </div>
  )
}

export default AboutSection
