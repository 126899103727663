import React from 'react'
import { makeStyles } from 'tss-react/mui';
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
    margin: '0 auto',
    paddingTop: '60px',
    paddingBottom: '60px',
  },
  portfolioItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.3)',
    },
    padding: '30px',
  },
}));

const PortfolioItem = ({ link, image }) => {
  const { classes } = useStyles()
  return (
    <div className={classes.portfolioItem}>
      <a href={link}>
        <Img fixed={image.fixed} />
      </a>
    </div>
  )
}

const PortfolioSection = () => {
  const { classes } = useStyles()
  const data = useStaticQuery(
    graphql`
      query {
        oltl: file(relativePath: { eq: "cds/cover_oltl.jpg" }) {
          childImageSharp {
            fixed(width: 400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        portrait: file(relativePath: { eq: "cds/cover_portrait.jpg" }) {
          childImageSharp {
            fixed(width: 400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        oldFashionedStories: file(
          relativePath: { eq: "cds/cover_old-fashioned-stories.jpg" }
        ) {
          childImageSharp {
            fixed(width: 400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <div className={classes.root}>
      <PortfolioItem
        link={'/cd-old-fashioned-stories'}
        image={data.oldFashionedStories.childImageSharp}
      />
      <PortfolioItem
        link={'/cd-one-life-to-live'}
        image={data.oltl.childImageSharp}
      />
      <PortfolioItem
        link={'/cd-portrait'}
        image={data.portrait.childImageSharp}
      />
    </div>
  )
}

export default PortfolioSection
