import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui';
import { useStaticQuery, graphql } from 'gatsby'
import { parseDateString } from '../../utils/dateUtils'
import { LinkBase } from '../link'

const useStyles = makeStyles()(theme => ({
  root: {
    paddingTop: '85px',
    paddingBottom: '85px',
    margin: '0 auto',
    maxWidth: '900px',
  },
  newsPreview: {
    borderBottom: '1px solid #222',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  newsPreviewHeading: {
    marginBottom: '10px',
  },
}));

const NewsPreview = props => {
  const { classes } = useStyles()
  const options = { year: 'numeric', month: 'long', day: '2-digit' }
  const dateString = new Intl.DateTimeFormat('de-DE', options).format(
    props.date
  )

  return (
    <div className={classes.newsPreview}>
      <Typography variant="h3" className={classes.newsPreviewHeading}>
        <LinkBase to={props.link} color="inherit" underline="none">
          {props.title}
        </LinkBase>
      </Typography>
      <Typography variant="caption">
        <LinkBase color="inherit" to={props.link}>
          {dateString}
        </LinkBase>
      </Typography>
    </div>
  )
}

const NewsSection = props => {
  const { classes } = useStyles()
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fields: { type: { eq: "news" } } }
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 7
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                date
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
  )
  const news = allMarkdownRemark.edges.map(({ node }) => (
    <NewsPreview
      title={node.frontmatter.title}
      date={parseDateString(node.frontmatter.date)}
      link={node.fields.slug}
      key={node.id}
    />
  ))

  return (
    <div {...props} className={classes.root}>
      {news}
    </div>
  )
}

export default NewsSection
